import React from "react";
import logo from "../../../image/as-logo.png";

export default function Index() {
  return (
    <div>
      <img src={logo} width="50px" />
    </div>
  );
}
